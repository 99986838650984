import React from 'react';
//import Helmet from 'react-helmet';
//import { Link } from 'gatsby';
//import { graphql } from 'gatsby';
import Article from '~/src/components/Article';

import Block from '~/src/components/Block';
import Figure from '~/src/components/Figure';

export const frontmatter = {
  id: 'eventador',
  isWork: true,
  subtitle: 'eventador',
  title: 'Uber for events.',
  date: '2019-03-01',
  cover: './eventador-hero.png',
  path: '/work/eventador/',
  excerpt: `In 2017, alognside a bunch of crazy-talented folks, I co-founded eventador — a platform that enables people to discover, attend and create events.`,
  details: [
    {
      title: 'Responsibility',
      description: 'Strategy, Product Design',
    },
    {
      title: 'Date',
      description: '2018-2019',
    },
    {
      title: 'Stack',
      description: 'iOS, Android, Web',
    },
  ],
};

export default props => (
  <Article {...props}>
    <Block mobilePull>
      <p>
       In 2017, alognside a bunch of crazy-talented folks, I co-founded eventador — a platform that enables people to discover, attend and create events.</p>
      <p>
       
      </p>
    </Block>
    <Block align="right">
      <p>
        Design-wise, for eventador I created an atomic design system in Figma that allows me to update everything, blazing fast. 
      </p>
      <p>
        The access to the platform is currently restricted to our local partners only. I will update this case study with more content as we make progress on the platform.
      </p>
    </Block>




  </Article>
);

